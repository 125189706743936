import React from 'react';
import loadable from '@loadable/component';

import { Layout } from '@components/layout';

const Detail = loadable(() => import('@screens/escala/Detail'));

function Index({ escalaId, ...props }) {
  const result = escalaId?.split('-') || [];
  return (
    <Layout {...props}>
      <Detail {...props} escalaId={result[0]} gestorId={result[1]} />
    </Layout>
  );
}

export default Index;
